:root {
	--primary-color: #0d2ccc;
	--secondary-color: #0897fe;
}

@media screen and (min-width: 768px) {
	.hero__header {
		width: 70%;
		padding: 4rem 0;
	}
}

.hero__header-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.hero__header-text h1 {
	font-size: 36px;
	line-height: 150%;
}
.hero__header-text h1 > span {
	color: var(--primary-color);
}
@media screen and (min-width: 768px) {
	.hero__header-text h1 {
		font-size: 48px;
	}

	p {
		font-size: 18px;
		line-height: 2;
	}
}

.header span {
	font-weight: bold;
	color: var(--secondary-color);
}

.header__text {
	font-size: 24px;
	margin: 0;
	line-height: 159%;
}
@media screen and (min-width: 768px) {
	.header__text {
		font-size: 36px;
		line-height: 159%;
	}
}

/* Images Section */
.images .col {
	flex-basis: 50%;
	margin-right: 0% !important;
}
.image1 {
	height: 100%;
}
.image1 img {
	height: 100%;
	object-fit: cover;
}

/* Capabilities Section */

.capabilities__content {
	align-items: center;
}

.capabilities__offerings {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 200%;
	background-color: var(--primary-color);
	margin: 0;
	padding: 50px 0;
}
.capabilities__offerings h3 {
	color: #fff;
}

.capabilities__offerings a {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 200%;
	text-decoration: none;
	color: #fff;
}

.capabilities__text > a:hover,
.capabilities__text > a:focus {
	color: var(--secondary-color);
}

@media screen and (min-width: 768px) {
	padding {
		margin: 5rem 0;
	}
	.capabilities__offerings,
	.capabilities__offerings a {
		font-size: 36px;
		line-height: 200%;
	}
}
/* Process Section */

section.process {
	position: relative;
	background: rgba(8, 151, 254, 0.05);
	padding: 100px 0;
}
.process .header {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.process svg {
	position: absolute;
    bottom: 0;
    right: 0;
    z-index: -2;
}
@media screen and (min-width: 999px) {
	.process .header {
		width: 50%;
		margin: 0 auto;
	}
}
.process .header span {
	align-self: flex-start;
}
.process__item h3 {
	font-size: 28px;
	margin-bottom: 20px;
}
.process__item {
	display: flex;
}
h3.color__blue {
	color: #0d2ccc;
	margin-right: 20px;
}

/* Lab Section */
section.lab {
	position: relative;
	padding: 100px 0;
}
.lab__content {
	align-items: center;
}

a.flagship-product {
	font-weight: 700;
	color: var(--primary-color);
	text-decoration: underline;
}

@media screen and (max-width: 768px) {
	.pattern,
	.lab-pattern,
	.process-pattern {
		width: 100%;
	}
	.lab-pattern {
		top: 50% !important;
	}
}

/* Team Page */
.team-page .hero__header-text {
	padding: 0 0 0 0;
}
.team-page .hero__header p {
	padding-bottom: 3rem;
	line-height: 150%;
}
.team-page .hero__header {
	padding: 3rem 0;
}
@media screen and (min-width: 768px) {
	.team-page .hero__header-text {
		font-size: 72px;
		line-height: 159%;
	}
}

.row {
	display: flex;
}

.col {
	flex-basis: 100%;
	align-items: stretch;
	margin-right: 0;
}

@media screen and (min-width: 768px) {
	.row {
		flex-wrap: wrap;
	}

	.col {
		flex-basis: 49%;
	}
	.col:first-child {
		margin-right: 2%;
	}
}

/* Why GuidedLabs Page */
section.introduction {
	padding: 100px 0;
}

section.topic {
	padding: 50px 0;
}
.topic .row {
	align-items: center;
}

/* Contact Page */

.contact {
	padding: 100px 0;
}
.contact__form .col:first-child {
	margin-right: 1%;
}
.input-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}
label {
	margin-bottom: 10px;
}
input,
textarea {
	background: rgba(58, 86, 131, 0.05);
	border: 1px solid rgba(13, 44, 204, 0.26);
	border-radius: 3px;
	padding: 10px;
}

textarea {
	min-height: 500px;
}

div.submit {
	display: flex;
	justify-content: center;
}
.contact .header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
button {
	color: #fff;
	padding: 10px 30px;
	background: #0d2ccc;
	border: 1px solid #0d2ccc;
}
