.container {
	width: 90%;
	margin: 0 auto;
}
.logo {
	cursor: pointer;
	width: 50%;
}
.hamburger {
	position: relative;
}
.hamburger {
	width: 30px;
}

.hamburger:after,
.hamburger:before,
.hamburger div {
	background-color: #0d2ccc;
	border-radius: 3px;
	content: '';
	display: block;
	height: 3px;
	margin: 7px 0;
	transition: all .2s ease-in-out;
}
.hamburger:before {
	width: 1rem;
}
.hamburger div {
	width: 1.5rem;
}
.hamburger:after {
	width: 2rem;
}
.hamburger.is-open:before {
	transform: translateY(10px) rotate(135deg);
	width: 2rem;
}

.hamburger.is-open:after {
	transform: translateY(-10px) rotate(-135deg);
	width: 2rem;
}

.hamburger.is-open div {
	transform: scale(0);
}
.navbar_nav.hide-nav {
	display: none;
	left: -1000px;
	background: transparent;
	transition: all ease-in 400ms;
}
.mobile__nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.nav-fixed{

	position: sticky;
	z-index: 20;
	top: 0;
	left: 0;
	right: 0;
	background-color: #000A2B;
}
.navbar {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}
.navbar_nav {
	background-color: #0d2ccc;
	display: flex;
	flex-direction: column;
	padding-left: 0.9rem;
	position: absolute;
	z-index: 25;
	top: 4rem;
	left: 0;
	right: 0;
	padding: 3rem;
}
.navbar_nav > .nav_item {
	list-style-type: none;
}
.navbar_nav > .nav_item > a {
	position: relative;
	text-decoration: none;
	padding-bottom: 0.7rem;
	font-size: 0.9rem;
	cursor: pointer;
	color: #fff;
}
@media only screen and (min-width: 1200px) {
	.navbar_nav > .nav_item > a {
		color: #24272b;
	}
}
.navbar_nav > .nav_item > a.active,.navbar_nav > .nav_item > a.active  {
	color: #0d2ccc;
}
.navbar_nav > .nav_item > a:before,
.navbar_nav > .nav_item > a:after {
	content: '';
	position: absolute;
	transition: transform .5s ease;
}

.navbar_nav > .nav_item > a:before {
	left: 0;
	bottom: 0;
	width: 100%;
	height: 3px;
	background: #0d2ccc;
	transform: scaleX(0);
}
.navbar_nav > .nav_item > a:hover:before, .navbar_nav > .nav_item > a.active:before {
	transform: scaleX(1);
}

.navbar_nav > .nav_item:not(last-child) {
	padding-bottom: 1.5rem;
}

@media only screen and (min-width: 1200px) {
	.hamburger {
		display: none;
	}
	.navbar {
		justify-content: space-between;
		flex-direction: row;
	}
	.navbar_nav {
		background-color: transparent;
		flex-direction: row;
		position: relative;
		top: 0;
		left: 0;
		right: 0;
		padding: 0%;
	}

	.navbar_nav.hide-nav {
		display: flex;
		left: 0px;
	}
	.navbar_nav > .nav_item {
		align-self: center;
	}
	.navbar_nav > .nav_item:not(last-child) {
		padding-right: 1.5rem;
		padding-bottom: 0rem;
	}
}
