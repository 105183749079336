footer {
    background-color: #0D2CCC;
    padding-top: 3rem;  
    position: relative;
}
h2.footer__header-text {
    font-weight: 500;
    font-size: 24px;
    line-height: 159%;

    color: #fff;
}

h5.footer__text {
    font-family: 'Graphik', sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 159%;

    color: #fff;
}
@media only screen and (min-width: 768px) {
    footer {
        padding-top: 7rem;  
    }
    h2.footer__header-text {
        font-size: 48px;
    }
    h5.footer__text {
        font-size: 24px;
    }
    footer .footer__top-content {
        width: 50%;
        margin: 0 auto;
    }
}
.footer__menu {
    margin-top: 7rem;
    padding-bottom: 2rem;
}
.footer__links .col-4 {
    margin-bottom: 1rem;
}

.footer__links .col-4 > a {
    display: block;
    text-decoration: none;
    color: #fff;
    line-height: 200%;
    font-size: 0.9rem;
}
.footer__links .col-4 > p{
    display: block;
    text-decoration: none;
    color: #fff;
    line-height: 200%;
    margin: 0;
    font-size: 0.9rem;
}
@media screen and (min-width: 768px){
    .footer__links .col-4 {
        margin-bottom: 0rem;
    }
    .copyright{
        justify-content: flex-end;
        align-self: flex-end;
        display: flex;
    }
}
span.copyright__text{
    font-size: 12px;
    font-weight: 200;
    color: #fff;
}

footer svg {
    position: absolute;
    bottom: 0;
    right: 0;
}