@import url('https://fonts.googleapis.com/css?family=Ibarra+Real+Nova:400,600,700&display=swap');

@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700&display=swap');

@font-face {
	font-family: 'Graphik';
	font-style: normal;
	font-weight: normal;
	src: url('./fonts/graphik/Graphik-Regular.eot'); /* IE9 Compat Modes */
	src: url('./fonts/graphik/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
		url('./fonts/graphik/Graphik-Regular.woff') format('woff'),
		url('./fonts/graphik/Graphik-Regular.ttf') format('truetype'),
		url('./fonts/graphik/Graphik-Regular.svg#Graphik-Regular') format('svg'); /* Legacy iOS */
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: 'Graphik';
	font-style: normal;
	font-weight: 300;
	src: url('./fonts/graphik/Graphik-Light.eot'); /* IE9 Compat Modes */
	src: url('./fonts/graphik/Graphik-Light.eot?#iefix') format('embedded-opentype'),
		url('./fonts/graphik/Graphik-Light.woff') format('woff'),
		Modern Browsers url('./fonts/graphik/Graphik-Light.ttf') format('truetype'),
		url('./fonts/graphik/Graphik-Light.svg#Graphik-Light') format('svg'); /* Legacy iOS */
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: 'Graphik';
	font-style: normal;
	font-weight: bold;
	src: url('./fonts/graphik/Graphik-Medium.eot'); /* IE9 Compat Modes */
	src: url('./fonts/graphik/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
		url('./fonts/graphik/Graphik-Medium.woff') format('woff'),
		url('./fonts/graphik/Graphik-Medium.ttf') format('truetype'),
		url('./fonts/graphik/Graphik-Medium.svg#Graphik-Medium') format('svg'); /* Legacy iOS */
	text-rendering: optimizeLegibility;
}

html,
body {
	height: 100%;
}

@font-face {
	font-family: 'MarketSans';
	font-style: normal;
	font-weight: normal;
	src: url('./fonts/marketsans/MarketSans-Regular.ttf'); /* IE9 Compat Modes */
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: 'MarketSansThin';
	font-style: normal;
	font-weight: bold;
	src: url('./fonts/marketsans/MarketSans-Thin.ttf'); /* IE9 Compat Modes */
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: 'MarketSansSemiBold';
	font-style: normal;
	font-weight: normal;
	src: url('./fonts/marketsans/MarketSans-SemiBold.ttf'); /* IE9 Compat Modes */
	text-rendering: optimizeLegibility;
}

body {
	margin: 0;
	font-family: "Graphik", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  font-size: 16px;
	box-sizing: border-box;
	overflow-x: hidden;
	color: #4a4a4a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Playfair Display', serif;
	font-weight: 700;
  color: #253C78;
  
}

h1 {
  margin: 0 !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.btn-primary {
	padding: 0.7rem 1.5rem;
	background-color: #0897fe;
	border: none;
	color: #fff;
}

.row {
	display: flex;
	flex-wrap: wrap;
}
.col-4 {
	flex-basis: 100%;
	margin-bottom: 2%;
}

.col-8,
.col-6 {
	flex-basis: 100%;
}

@media screen and (min-width: 768px) {
	
	.col-4 {
		flex-basis: 32%;
		margin-bottom: 0%;
	}
	.col-4:not(:last-child) {
		margin-right: 1%;
	}
	.col-6 {
		flex-basis: 49%;
	}
	.col-6:not(:last-child) {
		margin-right: 2%;
	}
	.col-8 {
		flex-basis: 64%;
	}
}

img {width: 100%; height: auto; display: block;}